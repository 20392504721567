/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import "./src/styles/global.scss"
import { AnalyticsProvider } from "./src/components/analyticsModule/AnalyticsContext"
import { ModalProvider } from "./src/components/Modal"

// PLace app root level providers here
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <AnalyticsProvider>
    <ModalProvider>{element}</ModalProvider>
  </AnalyticsProvider>
)
