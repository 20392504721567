import { useEffect, useState } from "react"
import onBrowser from "utils/onBrowser"

const useLocalStorage = key => {
  const [value, setValue] = useState(
    onBrowser ? localStorage.getItem(key) : null
  )

  useEffect(() => {
    if (value !== null) {
      localStorage.setItem(key, value)
    }
  }, [value])

  return [JSON.parse(value), setValue]
}

export default useLocalStorage
