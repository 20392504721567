import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import AppConstants from "constants/app"
import "./Modal.module.scss"

const ModalContext = React.createContext({
  openModalId: "",
  openModal: () => {},
  closeModal: () => {},
})

export class ModalProvider extends Component {
  constructor(...args) {
    super(...args)
    this.state = { openModalId: "" }
  }

  openModal(id) {
    this.setState({ openModalId: id })
    const mainElement = document.getElementById("___gatsby")
    if (mainElement) {
      mainElement.classList.add("modal-open")
    }
  }

  closeModal() {
    this.setState({ openModalId: "" })
    const mainElement = document.getElementById("___gatsby")
    if (mainElement) {
      mainElement.classList.remove("modal-open")
    }
  }

  render() {
    const { openModalId } = this.state
    const { children } = this.props

    return (
      <ModalContext.Provider
        value={{
          openModalId,
          openModal: id => this.openModal(id),
          closeModal: () => this.closeModal(),
        }}
      >
        {children}
      </ModalContext.Provider>
    )
  }
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ModalToggle = ({ id, children }) => (
  <ModalContext.Consumer>
    {({ openModal }) => children(() => openModal(id))}
  </ModalContext.Consumer>
)

ModalToggle.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
}

export const Modal = ({ id, children }) => (
  <ModalContext.Consumer>
    {({ closeModal, openModalId }) => {
      if (openModalId === id) {
        return ReactDOM.createPortal(
          <div className="modal">{children(closeModal)}</div>,
          document.getElementById(AppConstants.MODAL_PORTAL_ID)
        )
      }
      return null
    }}
  </ModalContext.Consumer>
)

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
}

export default {
  ModalProvider,
  ModalContext,
  Modal,
  ModalToggle,
}
