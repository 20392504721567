import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import useLocalStorage from "hooks/useLocalStorage"
import { trackGatedDownload } from "./trackingEvents"

const AnalyticsContext = React.createContext({})

const AnalyticsProvider = ({ children }) => {
  const [hasCookieConsent, setCookieConsent] = useLocalStorage("cookie-consent")

  return (
    <AnalyticsContext.Provider
      value={{
        trackGatedDownload,
        hasCookieConsent,
        setCookieConsent,
      }}
    >
      {hasCookieConsent && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-62839602-13"
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-62839602-13');`}
          </script>
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '953496398023477');
            fbq('track', 'PageView');`}
          </script>
          <script type="text/javascript">
            {`_linkedin_partner_id = "26376";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
          </script>

          <script type="text/javascript">
            {`(function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();`}
          </script>
        </Helmet>
      )}
      {children}
    </AnalyticsContext.Provider>
  )
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AnalyticsContext, AnalyticsProvider }
